import { useState, useEffect } from 'react';
//! pending pass useUserContext in this function to refactor the logic and user functions within the client
const UseUser = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(window.localStorage.getItem('refreshToken'));
  }, []);

  const existToken = () => {
    if (token === null) {
      return false;
    } else {
      return true;
    }
  };

  return { existToken };
};

export default UseUser;
