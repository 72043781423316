import { useEffect, useState } from 'react';

import { getNewToken, getUserContext } from '#api/user';

const useUserContext = () => {
  const [dataToReturn, setDataToReturn] = useState({ loading: true, data: null });

  const fetchUserContext = async (refreshToken, accessToken) => {
    try {
      const response = await getUserContext(accessToken);

      let dataToReturn = null;

      if (response.id) {
        dataToReturn = {
          id: response.id,
          firstName: response.first_name,
          lastName: response.last_name,
          email: response.email,
          docType: response.doc_type,
          docNumber: response.doc_number,
          phone: response.phone_number,
          country: response.country,
          role: response.role
        };
      } else {
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('refreshToken');
        window.localStorage.removeItem('userData');
      }

      setDataToReturn({ loading: false, data: dataToReturn });
      if (response.id) {
        window.localStorage.setItem('userData', JSON.stringify(dataToReturn));
      }
    } catch {
      try {
        const res = await getNewToken(refreshToken);
        window.localStorage.setItem('accessToken', JSON.stringify(res));
      } catch {
        console.log('Token invalido, algo salio mal');
      }
    }
  };

  useEffect(() => {
    const userData = window.localStorage.getItem('userData');
    const docValues = ['none', undefined];
    const hasNotDocNumber = docValues.includes(userData?.docNumber);
    const hasNotDocType = docValues.includes(userData?.docType);
    const accessToken = window.localStorage.getItem('accessToken');
    const refreshToken = window.localStorage.getItem('refreshToken');
    if (hasNotDocNumber && hasNotDocType && accessToken && refreshToken) {
      fetchUserContext(refreshToken, accessToken);
    }

    setDataToReturn({
      loading: false,
      data: userData ? JSON.parse(userData) : null
    });
  }, []);

  return dataToReturn;
};

export default useUserContext;
