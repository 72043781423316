import axios from 'axios';

import { baseUrl } from './baseUrl';

/* export const getToken = async data => {
  const response = await fetch(`${baseUrl}/api/token/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
}; */

export const getToken = async data => {
  const response = await axios.post(`${baseUrl}/api/token/`, data);
  return response;
};

export const getUserContext = async refreshToken => {
  const response = await fetch(`${baseUrl}/api/account/users/0/`, {headers: { Authorization: `Bearer ${refreshToken}` }});
  return response.json();
};

export const getNewToken = async refreshToken => {
  const response = await fetch(`${baseUrl}/api/token/refresh/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(refreshToken)
  });
  return response.json();
};

export const postNewUser = async data => {
  const response = await fetch(`${baseUrl}/api/account/register/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  });
  if (response.status === 400) {
    return response;
  }
  return response.json();
};

export const postNewUserAnonymous = async data => {
  const response = await axios.post(`${baseUrl}/api/account/register-anonymous/`, data);
  return response;
};

export const patchUserDocumentPayment = async data => {
  const response = await axios.patch(`${baseUrl}/api/account/user/document/`, data, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});
  return response;
};

export const getUserCheck = async data => {
  const response = await axios.post(`${baseUrl}/api/account/user-check/`, data);
  return response;
};

export const patchUser = async (id, data) => {
  const response = await axios.put(`${baseUrl}/api/account/users/${id}/`, data, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});

  return response;
};

export const getUserCheckReferrer = async data => {
  const response = await axios.post(`${baseUrl}/api/referral/email/`, data, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});
  return response;
};

export const getReferalCode = async data => {
  const response = await axios.post(`${baseUrl}/api/referral/code/`, data, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});
  return response;
};

export const getReferalValidation = async id => {
  const response = await axios.get(`${baseUrl}/api/referral/validate/${id}`);
  return response;
};

export const handleInvalidToken = ({ redirect }) => {
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('refreshToken');
  window.localStorage.removeItem('userData');
  if (!redirect) {
    window.location.reload();
  }
  window.location.href = redirect;
};

export const getUserGeolocalization = async () => {
  const { data } = await axios.post(
    'https://extreme-ip-lookup.com/json/?key=wh1DFcJWIBgQVum3is6G'
  );
  return data;
};

export const getMyChildrens = async () => {
  try {
    const response = await fetch(`${baseUrl}/api/students/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
      }
    });
    return response.json();
  } catch (e) {
    handleInvalidToken({ redirect: '/' });
  }
};

/* export const postChildren = async data => {
  const response = await fetch(`${baseUrl}/api/students/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`,
    },
    body: JSON.stringify(data),
  });
  return response.json();
}; */

export const postChildren = async data => {
  const response = await axios.post(`${baseUrl}/api/students/`, data, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});
  return response;
};

export const patchChildren = async (id, data) => {
  const response = await fetch(`${baseUrl}/api/students/${id}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}`
    },
    body: JSON.stringify(data)
  });
  return response.json();
};

/* export const getRecoveryEmail = async data => {
  const response = await fetch(`${baseUrl}/api/account/reset-password/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
}; */

export const getRecoveryEmail = async data => {
  const response = await axios.post(`${baseUrl}/api/account/reset-password/`, data);
  return response;
};

export const postNewUserPassword = async data => {
  const response = await fetch(`${baseUrl}/api/account/reset-password/confirm/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  });
  return response.json();
};

export const putPassword = async data => {
  const response = await axios.put(`${baseUrl}/api/account/change-password/`, data, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});
  return response;
};

export const getConfig = async () => {
  const response = await axios.get(`${baseUrl}/api/general-settings/0/`);
  return response;
};
