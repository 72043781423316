import { useState, useEffect } from 'react';

const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });
  const [isMobile, setIsMobile] = useState(undefined);
  const [isDesktop, setIsDesktop] = useState(undefined);

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== 'undefined') {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });

        if (window.innerWidth < 768) {
          setIsMobile(true);
          setIsDesktop(false);
        } else if (window.innerWidth > 768) {
          setIsMobile(false);
          setIsDesktop(true);
        }
      }

      // Add event listener
      window.addEventListener('resize', handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []); // Empty array ensures that effect is only run on mount

  return {
    width: windowSize.width,
    height: windowSize.height,
    isMobile,
    isDesktop
  };
};

export default useWindowSize;
