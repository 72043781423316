import { useEffect, useState } from 'react';

import { getUserGeolocalization } from '#api/user';

const useUserGeolocalization = () => {
  const [dataToReturn, setDataToReturn] = useState({ loading: true, data: null });

  const fetchUserGeolocalization = async () => {
    let dataToReturn;
    try {
      const response = await getUserGeolocalization();

      let currency;
      let currencySymbol;

      if (response.countryCode === 'MX') {
        currency = 'MXN';
        currencySymbol = 'MXN $';
      } else if (response.countryCode === 'PE') {
        currency = 'PEN';
        currencySymbol = 'S/';
      } else if (response.countryCode === 'CO') {
        currency = 'COP';
        currencySymbol = 'COP $';
      } else if (response.countryCode === 'AR') {
        currency = 'ARS';
        currencySymbol = 'ARS $';
      } else if (response.countryCode === 'CL') {
        currency = 'CLP';
        currencySymbol = 'CLP $';
      } else if (response.countryCode === 'CR') {
        currency = 'CRC';
        currencySymbol = 'CRC ₡';
      } else if (response.countryCode === 'UY') {
        currency = 'UYU';
        currencySymbol = 'UYU $';
      } else {
        currency = 'USD';
        currencySymbol = 'USD $';
      }

      if (response.countryCode !== 'PE') {
        window.localStorage.setItem('free_class', 'alternative-page');
      }

      dataToReturn = {
        country: response.country || '',
        countryCode: response.countryCode || '',
        city: response.city || '',
        continent: response.continent || '',
        ipAddress: response.query || '',
        currency: currency || '',
        currencySymbol: currencySymbol || ''
      };
    } catch {
      dataToReturn = {
        country: 'United States',
        countryCode: 'US',
        city: 'Houston',
        continent: '',
        ipAddress: '',
        currency: 'USD',
        currencySymbol: 'USD $'
      };
    }

    setDataToReturn({ loading: false, data: dataToReturn });
    window.localStorage.setItem('userGeolocationData', JSON.stringify(dataToReturn));
  };

  const fetchUserGeoFreeClass = async () => {
    const response = await getUserGeolocalization();
    if (response.countryCode === 'CR' || response.countryCode === 'PA') {
      window.localStorage.setItem('free_class', 'alternative-page');
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem('country') == undefined) {
      window.localStorage.removeItem('userGeolocationData');
      const userGeolocationData = window.localStorage.getItem('userGeolocationData');
      if (!userGeolocationData) {
        fetchUserGeolocalization();
      } else {
        setDataToReturn({
          loading: false,
          data: userGeolocationData ? JSON.parse(userGeolocationData) : null
        });
      }
    } else {
      let dataToReturn = {};
      if (window.localStorage.getItem('country') == 'MX') {
        window.localStorage.setItem('free_class', 'alternative-page');
        dataToReturn = {
          country: 'México',
          countryCode: window.localStorage.getItem('country'),
          city: 'México City',
          continent: 'North America',
          ipAddress: '',
          currency: 'MXN',
          currencySymbol: 'MXN $'
        };
      } else if (window.localStorage.getItem('country') == 'CO') {
        window.localStorage.setItem('free_class', 'alternative-page');
        dataToReturn = {
          country: 'Colombia',
          countryCode: window.localStorage.getItem('country'),
          city: 'Bogotá',
          continent: 'South America',
          ipAddress: '',
          currency: 'COP',
          currencySymbol: 'COP $'
        };
      } else if (window.localStorage.getItem('country') == 'PE') {
        dataToReturn = {
          country: 'Perú',
          countryCode: window.localStorage.getItem('country'),
          city: 'Lima',
          continent: 'South America',
          ipAddress: '',
          currency: 'PEN',
          currencySymbol: 'S/'
        };
      } else if (window.localStorage.getItem('country') == 'AR') {
        dataToReturn = {
          country: 'Argentina',
          countryCode: window.localStorage.getItem('country'),
          city: 'Buenos Aires',
          continent: 'South America',
          ipAddress: '',
          currency: 'ARS',
          currencySymbol: 'ARS $'
        };
      } else if (window.localStorage.getItem('country') == 'CL') {
        dataToReturn = {
          country: 'Chile',
          countryCode: window.localStorage.getItem('country'),
          city: 'Santiago',
          continent: 'South America',
          ipAddress: '',
          currency: 'CLP',
          currencySymbol: 'CLP $'
        };
      } else if (window.localStorage.getItem('country') == 'CR') {
        dataToReturn = {
          country: 'Costa Rica',
          countryCode: window.localStorage.getItem('country'),
          city: 'San Jose',
          continent: 'North America',
          ipAddress: '',
          currency: 'CRC',
          currencySymbol: 'CRC ₡'
        };
      } else if (window.localStorage.getItem('country') == 'UY') {
        dataToReturn = {
          country: 'Uruguay',
          countryCode: window.localStorage.getItem('country'),
          city: 'Montevideo',
          continent: 'South America',
          ipAddress: '',
          currency: 'UYU',
          currencySymbol: 'UYU $'
        };
      } else {
        window.localStorage.setItem('free_class', 'alternative-page');
        dataToReturn = {
          country: 'United States',
          countryCode: window.localStorage.getItem('country'),
          city: 'Houston',
          continent: '',
          ipAddress: '',
          currency: 'USD',
          currencySymbol: 'USD $'
        };
      }

      setDataToReturn({
        loading: false,
        data: dataToReturn
      });
    }
  }, []);

  return dataToReturn;
};

export default useUserGeolocalization;
