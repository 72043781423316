import { useRouter } from 'next/router';
import { useLayoutEffect, useState } from 'react';

const withAuth = WrappedComponent => {
  return props => {
    const Router = useRouter();
    const [verified, setVerified] = useState(false);

    useLayoutEffect(() => {
      const accessToken = localStorage.getItem('refreshToken');
      // if no accessToken was found,then we redirect to "/" page.
      if (!accessToken) {
        Router.replace('/');
      } else {
        setVerified(true);
      }
    }, []);

    if (verified) {
      return <WrappedComponent {...props} />;
    } else {
      return <h1>Upps! no tienes acceso a esta pagina, redireccionando...</h1>;
    }
  };
};

export default withAuth;
